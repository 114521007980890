import '../styles/globals.scss'
import '../styles/rc-select.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'src/theme/theme'
import dynamic from 'next/dynamic'

import client from 'src/util/graphqlClient'
import React from 'react'
import { CommonMeta } from 'src/components/CommonMeta'

import { initializeApp, getApps } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDVlb32Ss1FXxvMA0qmFY9ArbjFIeA1KY0',
  authDomain: 'jammy-cli.firebaseapp.com',
  projectId: 'jammy-cli',
  storageBucket: 'jammy-cli.appspot.com',
  messagingSenderId: '840609148359',
  appId: '1:840609148359:web:1a8bfb1ba2ce596b2d7de2',
  measurementId: 'G-V1FBGQCQ5Q',
}

if (getApps.length < 1) {
  initializeApp(firebaseConfig)
}

function MyApp({ Component, pageProps }: AppProps) {
  const SafeHydrate = dynamic(() => import('src/util/safeHydrate'), {
    ssr: false,
  })

  return (
    <SafeHydrate>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <CommonMeta title="予約管理システム「Jammy」" />
          <Component {...pageProps} />
        </ChakraProvider>
      </ApolloProvider>
    </SafeHydrate>
  )
}
export default MyApp
