import Head from 'next/head'
import { FC } from 'react'

type Props = {
  title?: string
  description?: string
}

const GA_TRACKING_ID = 'UA-210762375-2'

export const CommonMeta: FC<Props> = ({ title, description }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta property="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="robots" content="noindex,nofollow" />
      {/* <meta property="og:image" content={`${process.env.SITE_URL}/ogp_large.png`} />
      <meta name="twitter:card" content="summary_large_image"/> */}

      {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
        <>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });`,
            }}
          />
        </>
      ) : null}
    </Head>
  )
}
