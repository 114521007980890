import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  colors: {
    primary: '#FF0B5E',
  },
  fonts: {
    body: '-apple-system, Hiragino Kaku Gothic ProN, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  styles: {
    global: {
      body: {
        backgroundColor: '#f4f7fb',
      },
    },
  },

  components: {
    Textarea: {
      sizes: {
        md: {
          maxWidth: '780px',
          height: '140px',
          fontSize: '16px',
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            maxWidth: '780px',
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
    NumberInput: {
      sizes: {
        md: {
          field: {
            maxWidth: '780px',
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#4F5464',
      },
    },
    Select: {
      sizes: {
        md: {
          field: {
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
  },
})
