export enum GraphqlErrorCode {
  BAD_REQUEST = 'BAD_REQUEST',
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  PERMISSION_ERROR = 'PERMISSION_ERROR',
  ARGUMENT_ERROR = 'ARGUMENT_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  RECORD_INVALID = 'RECORD_INVALID',
  REQUIRED_SUBSCRIPTION_ERROR = 'REQUIRED_SUBSCRIPTION_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  STRIPE_CAPTURE_ERROR = 'STRIPE_CAPTURE_ERROR',
}
