import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { getAuth } from 'firebase/auth'
import { GraphqlErrorCode } from './graphqlErrors'
import { CustomGraphqlError } from './types/customGraphqlError'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((e) => {
      console.log(
        `[GraphQL error]: Message: ${e.message}, Location: ${e.locations}, Path: ${e.path}`
      )

      // stripeエラーの時は別でモーダルを表示してるためスキップ
      if (
        (e as CustomGraphqlError).extensions?.code ===
        GraphqlErrorCode.STRIPE_CAPTURE_ERROR
      )
        return

      alert(e.message ?? 'エラーが発生しました。')
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    alert('サーバーエラーが発生しました。')
  }
})

const authLink = setContext(async (_, { headers }) => {
  const auth = getAuth()

  if (!auth || !auth.currentUser) return

  try {
    const token = await auth.currentUser.getIdToken(true)

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  } catch (err) {
    console.log(`[Authorization error]: ${err}`)
  }
})

// FIXME: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
const httpLink = createUploadLink({
  uri: `${process.env.API_URL}/graphql`,
})

const cache = new InMemoryCache({
  typePolicies: {
    Hotel: {
      fields: {
        roomTypeList: {
          ...offsetLimitPagination(),
          merge: true,
        },
        roomList: {
          ...offsetLimitPagination(),
          merge: true,
        },
        reservationList: {
          ...offsetLimitPagination(),
          merge: true,
        },
        planList: {
          ...offsetLimitPagination(),
          merge: true,
        },
      },
    },
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    httpLink as unknown as ApolloLink,
  ]),
  cache,
})

export default client
